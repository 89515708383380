export default {
  features_options: {
    '90S': '90 seconds Ads',
    '60S': '60 seconds Ads',
    DV90S: 'DV-DCM 90 seconds',
    '30S': 'DV-DCM 30 seconds',
    '15S': 'DV-DCM 15 seconds',
    AT: 'Audience Targeting',
    '3PD': '3rd Party Deal',
    // '3PT': 'DV-DCM',
  },
}
