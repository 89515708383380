
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import Inventory from '@/models/Inventory'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import inventory_items_fields from './inventory-items-fields'

@Component({
  components: {
    DataTable,
  },
})
export default class InventoryLineItemsTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop()
  public adunit!: any

  @Prop()
  public range!: any

  public adunit_items: any = []

  public page_size: number = 25

  public sort_by: string = 'name'

  public sort_desc: boolean = false

  public fields: any = inventory_items_fields

  public loading: boolean = false

  public ready: boolean = false

  public fieldFilters: any = {}

  public records: number = 0

  public query: string[] = []

  @Watch('range')
  public onRangeChange(val) {
    if (val && !this.loading && this.dataTable) {
      this.dataTable.refresh()
    }
  }

  public get show_fields() {
    return this.fields.filter(f => f.show)
  }

  public mounted() {
    // this.loadFilters()
    this.ready = true
  }

  public loadItems(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    // this.syncFilters()
    return Inventory.getLineItems(this.adunit.id, this.range, {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(response => {
      this.records = response.records
      this.loading = false
      this.adunit_items = response.results
      return response.results
    })
  }

  // public loadFilters() {
  //   const system = getModule(SystemtModule)
  //   system.getFilter('inventories-line-items').then((filter: any) => {
  //     if (filter) {
  //       this.query = filter.query
  //       this.fieldFilters = filter.fieldFilters
  //     }
  //     this.ready = true
  //   })
  // }

  // public syncFilters() {
  //   const system = getModule(SystemtModule)
  //   system.updateState({
  //     name: 'filters',
  //     type: 'inventories',
  //     data: { query: this.query, fieldFilters: this.fieldFilters },
  //   })
  // }

  // public clearFilters() {
  //   const system = getModule(SystemtModule)
  //   system.updateState({
  //     name: 'filters',
  //     type: 'inventories',
  //     data: null,
  //   })
  // }

  // // Reset filters
  // public resetFilters() {
  //   this.fieldFilters = {}
  //   this.query = ['is_not:archived', 'is:cim']
  //   this.clearFilters()
  //   this.dataTable.refresh()
  // }
}
