
import {
  Component, Prop, Watch, Vue, Ref,
} from 'vue-property-decorator'
import Inventory from '@/models/Inventory'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Creative from '@/models/Creative'
import SelectOption from '@/models/interface/SelectOption'
import PublisherPicker from '@/components/PublisherPicker/PublisherPicker.vue'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import IconAction from '@/components/IconAction/IconAction.vue'
import SpecialFeaturesPicker from '@/components/MediaPlanBuilder/SpecialFeaturesPicker.vue'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'
import { clone } from 'lodash'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import {
  AdserverTypes,
  SchedulerModes,
  RemoteConnections,
  CategoryAuthorityOptions,
  CategoryAuthorityVersionOptions,
} from '@/models/interface/Common'
import AdunitPicker from '@/components/AdunitPicker/AdunitPicker.vue'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import Company from '@/models/Company'
import dmaCodes from '@/data/dma_options'
import stateOptions from '@/data/state_options'
import object_maps from '../object_maps'

Vue.directive('mask', VueMaskDirective)
@Component({
  components: {
    CompanyPicker,
    SelectPicker,
    FormInput,
    PublisherPicker,
    SpecialFeaturesPicker,
    IconAction,
    FooterNav,
    CheckboxInput,
    AdunitPicker,
    EventTracker,
  },
})
export default class InventoryForm extends ViewModel {
  @Ref() createTable!: any

  @Ref() readonly eventTracker!: EventTracker

  @Prop()
  public id!: string

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public hideFooter!: boolean

  @Prop({ default: false })
  public isLoading!: boolean

  @Prop()
  public adunit!: any

  public view_mode!: string

  public show_sidebar!: boolean

  public modal: any = {
    empty: false,
  }

  public inventory: Inventory = new Inventory()

  public loading: boolean = false

  public adding_special_rates: boolean = false

  public editing_special_rates: boolean = false

  public temp_special_rate_index: any = 0

  public busy = true

  public devices: string[] = []

  public remote_inventories: any = []

  public remote_contents: any = []

  public remote_labels: any = []

  public temp_inventory_special_rate: any = {
    type: null,
    model: 'surcharge',
    value: 0,
    priority: 1,
  }

  public inventory_map_fields = [
    {
      key: 'code',
      label: 'External Client Code',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thStyle: { width: '40%' },
      thClass: 'text-center',
    },
    {
      key: 'target',
      label: 'Target',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'actions',
      label: ' ',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thStyle: { width: '100px' },
      thClass: 'align-middle text-center',
    },
  ]

  public special_rates_fields: any = [
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'model',
      label: 'Model',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'value',
      label: 'Value',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'action',
      label: 'Actions',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
  ]

  @Watch('isLoading')
  public isLoadingOnChange(val) {
    this.loading = val
  }

  public get macros() {
    return this.eventTracker?.macros
  }

  public get schduler_modes() {
    return SchedulerModes
  }

  public get remote_connections() {
    return RemoteConnections
  }

  public get authority_options() {
    return CategoryAuthorityOptions
  }

  public get authority_version_options() {
    if (!CategoryAuthorityVersionOptions[this.inventory.category_authority]) {
      return []
    }
    return CategoryAuthorityVersionOptions[this.inventory.category_authority]
  }

  public status_options = [
    {
      value: true,
      name: 'Active',
    },
    {
      value: false,
      name: 'Inactive',
    },
  ]

  public apply_to_children_rate = [
    {
      value: true,
      name: 'On',
    },
    {
      value: false,
      name: 'Off',
    },
  ]

  public bool_options = [
    {
      value: false,
      name: 'Yes',
    },
    {
      value: true,
      name: 'No',
    },
  ]

  public model_options = [
    {
      value: 'surcharge',
      name: 'Surcharge',
    },
    {
      value: 'base',
      name: 'Base',
    },
    {
      value: 'absolute',
      name: 'Absolute',
    },
  ]

  public get features_options(): any {
    return object_maps.features_options
  }

  public get device_options() {
    return Inventory.module.device_options
  }

  public get adserver_options() {
    return AdserverTypes
  }

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get publisher_options() {
    return Inventory.module.publisher_options
  }

  public get live_options() {
    return Inventory.module.live_options
  }

  public get dma_options() {
    return dmaCodes
  }

  public get state_options() {
    return stateOptions
  }

  public get creative_type_options() {
    const options = Creative.module.type_options.map(a => ({ ...a }))
    options.unshift(new SelectOption('Any', null))
    return options
  }

  public addTag(newTag: string) {
    this.report_name_options.push(new SelectOption(newTag, newTag))
    this.inventory.publisher_name = newTag
  }

  public report_name_options: SelectOption[] = []

  @Watch('devices')
  public updateDevices(val) {
    if (val && val.length) {
      this.inventory.ctv = this.devices.includes('ctv')
      this.inventory.mobile = this.devices.includes('mobile')
      this.inventory.desktop = this.devices.includes('desktop')
    }
  }

  @Watch('inventory.publisher_id')
  public updatePublisher(val) {
    if (val && this.inventory.rate == 0) {
      Company.find(val).then((o: Company) => {
        if (this.inventory.rate == 0) {
          this.inventory.rate = o.rate
        }
      })
    }
  }

  @Watch('inventory.parent_id')
  public updateParent(val) {
    if (val && !this.inventory.publisher_name) {
      Inventory.get(val).then((o: Inventory) => {
        if (!this.inventory.publisher_name && o.publisher_name) {
          this.inventory.publisher_name = o.publisher_name
        }
      })
    }
  }

  public get masks() {
    return {
      currencyMask,
      percentageMask,
    }
  }

  public addSpecialRate() {
    let tmp = clone(this.temp_inventory_special_rate)
    tmp.value = parseFloat(tmp.value.replaceAll('$', ''))
    const obj = JSON.parse(JSON.stringify(tmp))

    if (this.editing_special_rates) {
      Vue.set(this.inventory.special_rates, this.temp_special_rate_index, obj)
      // this.inventory.special_rates[this.temp_special_rate_index] = obj
      this.editing_special_rates = false
      this.temp_special_rate_index = 0
    } else {
      this.inventory.special_rates.push(obj)
    }

    setTimeout(() => {
      this.resetTempSpecialRate(true)
    }, 200)
  }

  public resetTempSpecialRate(cancel = false) {
    this.temp_inventory_special_rate = {
      type: null,
      model: 'surcharge',
      value: 0,
      priority: 1,
    }
    this.editing_special_rates = false
    if (cancel) {
      this.adding_special_rates = false
    }
  }

  public editSpecialRate(target: any) {
    let index = this.inventory.special_rates.findIndex(
      (i: any) =>
        i.type === target.type
        && i.model === target.model
        && i.value === target.value
        && i.priority === target.priority,
    )
    this.temp_special_rate_index = index
    this.editing_special_rates = true
    this.temp_inventory_special_rate = { ...clone(this.inventory.special_rates[index]) }
    this.adding_special_rates = true
  }

  public removeSpecialRate(target: any) {
    let index = this.inventory.special_rates.findIndex(
      (i: any) =>
        i.type === target.type
        && i.model === target.model
        && i.value === target.value
        && i.priority === target.priority,
    )
    this.inventory.special_rates.splice(index, 1)
  }

  public update() {
    this.loading = true
    this.inventory
      .save()
      .then((response: any) => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Inventories' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public onSubmit() {
    this.loading = true
    this.update()
  }

  public cancel() {
    this.$router.push({ name: 'Inventories' })
  }

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  public init() {
    Inventory.loadReportNameOptions().then((o: any) => {
      this.report_name_options = o

      if (this.adunit) {
        this.inventory = { ...this.adunit }
        this.devices = this.inventory.devices
        this.busy = false
        return
      }

      if (this.id) {
        this.busy = true
        Inventory.get(this.id).then(o => {
          if (o instanceof Inventory) {
            this.inventory = o
            this.devices = this.inventory.devices

            this.remote_inventories = this.inventory.remote_data.remote_inventory_ids.map(
              (o: string) => new SelectOption(o, o),
            )

            this.remote_labels = this.inventory.remote_data.remote_label_ids.map(
              (o: string) => new SelectOption(o, o),
            )

            this.remote_contents = this.inventory.remote_data.remote_content_ids.map(
              (o: string) => new SelectOption(o, o),
            )
          }
          this.busy = false
        })
      } else {
        this.inventory = new Inventory()
        const { query } = this.$route
        if (query.parent_id && typeof query.parent_id === 'string') {
          this.inventory.parent_id = query.parent_id
        }
        this.busy = false
      }
    })
  }

  public addRemoteTag(newTag: string) {
    const tags = newTag
      .split(',')
      .map((o: string) => o.trim())
      .filter((o: string) => o.length > 0)

    tags.forEach((tag: string) => {
      this.remote_inventories.push(new SelectOption(tag, tag))
    })

    setTimeout(() => {
      this.inventory.remote_data.remote_inventory_ids.push(...tags)
    }, 100)
  }

  public addRemoteContent(newTag: string) {
    const tags = newTag
      .split(',')
      .map((o: string) => o.trim())
      .filter((o: string) => o.length > 0)

    tags.forEach((tag: string) => {
      this.remote_contents.push(new SelectOption(tag, tag))
    })

    setTimeout(() => {
      this.inventory.remote_data.remote_content_ids.push(...tags)
    }, 100)
  }

  public addRemoteLabel(newTag: string) {
    const tags = newTag
      .split(',')
      .map((o: string) => o.trim())
      .filter((o: string) => o.length > 0)

    tags.forEach((tag: string) => {
      this.remote_labels.push(new SelectOption(tag, tag))
    })

    setTimeout(() => {
      this.inventory.remote_data.remote_label_ids.push(...tags)
    }, 100)
  }

  public mounted() {
    this.init()
  }
}
