export default [
  {
    key: 'id',
    label: 'Id',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'order',
    label: 'Order',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'total_impressions',
    label: 'Impressions',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'start_at',
    label: 'flight Dates',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle text-capitalize',
  },
]
