
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import Inventory from '@/models/Inventory'
import ViewModel from '@/models/ViewModel'
import { Component, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import DatePickerDate from '@/models/DatePickerDate'
import InventoryLineItemsTable from './components/InventoryLineItemsTable.vue'
import InventoryForm from './components/InventoryForm.vue'

@Component({
  components: {
    PageHeader,
    InventoryForm,
    Widget,
    InventoryLineItemsTable,
    DatePicker,
  },
})
export default class InventoryView extends ViewModel {
  public adunit: Inventory | any = null

  public loading: boolean = true

  public activeTab: number = 0

  public selected_dates: DatePickerDate = new DatePickerDate(null, null, 'week')

  public selectTabByParam() {
    if (!this.$route.query || !this.$route.query.tab) return
    const tabs: any = {
      overview: 0,
      'line-items': 1,
    }
    Vue.set(this, 'activeTab', tabs[this.$route.query.tab] || 0)
  }

  public loadAdunit() {
    Inventory.get(this.$route.params.id).then(response => {
      this.adunit = response

      this.loading = false

      this.selectTabByParam()
    })
  }

  public created() {
    this.loadAdunit()
  }
}
